import { Route } from '@angular/router';
import { authGuard, loginGuard } from '@expocab/core';
import { ROUTE_PARAMS_TOKENS, ROUTE_TOKENS } from '@expocab/shared/app-config';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: `${ROUTE_TOKENS.LOGIN}` },
  {
    canActivate: [loginGuard],
    loadChildren: () =>
      import('@expocab/pages/login').then((m) => m.loginRoutes),
    path: ROUTE_TOKENS.LOGIN,
  },
  {
    loadChildren: () =>
      import('@expocab/pages/disposable-login').then(
        (m) => m.disposableLoginRoutes,
      ),
    path: ROUTE_TOKENS.DISPOSABLE_LOGIN,
  },
  {
    loadChildren: () =>
      import('@expocab/pages/registration').then((m) => m.registrationRoutes),
    path: ROUTE_TOKENS.REGISTRATION,
  },
  {
    canActivate: [loginGuard],
    loadComponent: () =>
      import('@expocab/pages/registration-success').then(
        (m) => m.RegistrationSuccessComponent,
      ),
    path: ROUTE_TOKENS.REGISTRATION_SUCCESS,
  },
  {
    canActivate: [loginGuard],
    loadChildren: () =>
      import('@expocab/pages/password-recovery').then(
        (m) => m.passwordRecoveryRoutes,
      ),
    path: ROUTE_TOKENS.PASSWORD_RECOVERY,
  },
  {
    loadChildren: () =>
      import('@expocab/pages/new-password').then((m) => m.newPasswordRoutes),
    path: ROUTE_TOKENS.RESTORE_PASSWORD,
  },
  {
    loadChildren: () =>
      import('@expocab/pages/new-password-success').then(
        (m) => m.newPasswordSuccessRoutes,
      ),
    path: ROUTE_TOKENS.RESTORE_PASSWORD_SUCCESS,
  },
  {
    canActivate: [authGuard],
    children: [
      {
        loadChildren: () =>
          import('@expocab/pages/profile').then((m) => m.profileRoutes),
        path: ROUTE_TOKENS.PROFILE.PROFILE_BASE,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/exhibitions').then((m) => m.exhibitionsRoutes),
        path: ROUTE_TOKENS.EXHIBITIONS,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/exhibition-overview').then(
            (m) => m.exhibitionRoutes,
          ),
        path: `${ROUTE_TOKENS.EXHIBITIONS}/:${ROUTE_PARAMS_TOKENS.EXHIBITION_ID}`,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/faq').then((m) => m.faqRoutes),
        path: ROUTE_TOKENS.FAQ,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/archive-exhibitions').then(
            (m) => m.archiveExhibitionsRoutes,
          ),
        path: ROUTE_TOKENS.ARCHIVE.ARCHIVE_BASE,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/news').then((m) => m.newsRoutes),
        path: `${ROUTE_TOKENS.NOTIFICATION}/:${ROUTE_PARAMS_TOKENS.NOTIFICATION_ID}`,
      },
      {
        loadChildren: () =>
          import('@expocab/pages/admin/admin-shell').then((c) => c.adminRoutes),
        path: `${ROUTE_TOKENS.ADMIN.ADMIN}`,
      },
    ],
    loadComponent: () =>
      import('./layout.component').then((c) => c.LayoutComponent),
    path: '',
  },
];
