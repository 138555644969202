import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import {
  APP_BASE_HREF,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipeConfig,
  registerLocaleData,
} from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  isDevMode,
} from '@angular/core';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Router,
  provideRouter,
  withComponentInputBinding,
  // withDebugTracing,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { AuthInterceptor, metaReducers } from '@expocab/core';
import {
  NotificationEffects,
  notificationFeature,
} from '@expocab/entities/notification';
import { ProjectEffects, projectFeature } from '@expocab/entities/project';
import { UserEffects, userFeature } from '@expocab/entities/user';
import { EXPOCAB_CONFIG, EXPOCAB_DI_CONFIG } from '@expocab/shared/app-config';
import { ToastComponent } from '@expocab/ui/common/toast';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as SentryBrowser from '@sentry/angular-ivy';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideToastr } from 'ngx-toastr';

import { appRoutes } from './app.routes';
import { ApiConfiguration } from './swagger/api-configuration';

registerLocaleData(localeRu);
const DEFAULT_LOCALE = 'ru-RU';
const DEFAULT_TIMEZONE = 'UTC+3';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ErrorHandler,
      useValue: SentryBrowser.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      deps: [Router],
      provide: SentryBrowser.TraceService,
    },
    {
      deps: [SentryBrowser.TraceService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
    },
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      // withDebugTracing(),
    ),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    provideAngularSvgIcon(),
    provideToastr({
      disableTimeOut: 'extendedTimeOut',
      positionClass: 'expocab-inline-toasts',
      tapToDismiss: false,
      timeOut: 10_000,
      toastClass: '',
      toastComponent: ToastComponent,
    }),
    provideStore(
      {
        router: routerReducer,
      },
      {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: false,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      },
    ),
    provideState(userFeature),
    provideState(projectFeature),
    provideState(notificationFeature),
    provideEffects([UserEffects, ProjectEffects, NotificationEffects]),
    provideRouterStore(),
    isDevMode()
      ? provideStoreDevtools({
          logOnly: !isDevMode(),
          maxAge: 25,
          name: 'Expocab NGRX Store',
        })
      : [],
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: EXPOCAB_CONFIG, useValue: EXPOCAB_DI_CONFIG },
    {
      provide: ApiConfiguration,
      useValue: { rootUrl: '/cabinet' },
    },
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: <DatePipeConfig>{ timezone: DEFAULT_TIMEZONE },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        hideRequiredMarker: true,
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{ disabled: true },
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: <DialogConfig>{
        backdropClass: 'expocab-backdrop',
        closeOnNavigation: true,
        hasBackdrop: true,
        panelClass: 'expocab-dialog-panel',
      },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: <MatTooltipDefaultOptions>{
        position: 'above',
      },
    },
  ],
};
